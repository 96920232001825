import $ from 'jquery';

export default () => {

  $(function () {

    $('.downloads__form').validate({
      onkeyup: false,
      onfocus: false,
      onsubmit: true,
      rules: {
        email: {
          required: true,
          email: true
        },
        state: "required"
      },
      messages: {
        email: {
          required: "Please enter your email",
          email: "Emails must contain @ and ."
        },
        state: "If US, please provide your state; otherwise select Canada/non-US."
      },
      submitHandler: function(form){

        // Show the loading state
        $('.formfield__submit').parent().addClass('loading');

        function onSuccess(data) {
          $('.downloads__form__wrap').fadeOut('fast');
          $('.downloads__thankyou').fadeIn('slow');
        }

        import('./reCaptchaValidate').then(({default: validate}) => {
          validate($(form).data('placement'))
          .then((result) => {

            var $form = $(form),
            $partner_email_optin = $('#partner_email_optin'),
            $partner_optin_name = $form.data('partner_optin_name'),
            data = {
              'firstname' : $form.find('input[id=firstname]').val(),
              'lastname' : $form.find('input[id=lastname]').val(),
              'email' : $form.find('input[type=email]').val(),
              'state' : $form.find('select[id=state]').val(),
              'taunton_optin' : 0,
              'partner_optin_name' : $partner_optin_name,
              'partner_optin' : 0
            },
            $optin = false;

            // Exlicitly set optin to 1 if checkbox is hidden but active or if the checkbox is visible and checked.
            if (($partner_email_optin.hasClass('hide') && !$partner_email_optin.hasClass('disabled')) || $form.find('#partner_newsletter_optin').prop('checked')) {
              data['partner_optin'] = 1;
            }

            if ($form.find('#taunton_newsletter_optin').prop('checked')) {
              data['taunton_optin'] = 1;
              $optin = true;
            }

            // If Taunton optin checked; send email to Experian.
            if ($optin) {
              import('./processEmailForm').then(({default: processEmailForm}) => {
                processEmailForm(form)
              });
            }

            // Save Optin to Wordpress Database - Saves partner optin info
            data['action'] = 'taunton_partner_optin';
            $.post('/wp/wp-admin/admin-ajax.php', data, onSuccess(data));

          })
          .catch((result) => {
            $(form).replaceWith('<p>Spammer Detected!</p>');
            console.log(result);
          })
        });
      }
    });
  });
}
