import $ from 'jquery';

export default () => {

  function handleExpandableMenu() {
    const $body: JQuery = $('body'),
      $html: JQuery = $('html'),
      $expandableMenu: JQuery = $('[data-behavior="expandable-menu"]');

    $body.on('click', '[data-behavior="expandable-menu-trigger"]', function (e) {
      e.preventDefault();

      const $container: JQuery = $(e.target).closest('.siteheader__actions__member'),
        $this: JQuery = $(e.target),
        $moreDropdown: JQuery = $this.next('ul');

      if ($container.hasClass('menu-open')) {
        $container.removeClass('menu-open');
      } else {
        $('.menu-open').removeClass('menu-open');
        $container.addClass('menu-open');
      }

      // only allow 1 expandable menu to be open at a time
      if ($expandableMenu.hasClass('open') && !$moreDropdown.hasClass('open')) {
        $expandableMenu.removeClass('open');
      }

      if ($moreDropdown.hasClass('open')) {
        $moreDropdown.removeClass('open');
      } else {
        $moreDropdown.addClass('open');
        $html.trigger('more-list-open');
      }
    });
  }

  $(function () {
    handleExpandableMenu();
  });

};
