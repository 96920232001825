import $ from "jquery";

const promoImpression = () => {
  const $home = document.querySelector('body.homepage');
  if ($home) {
    $.publish('threads.marketing.impression', 'Homepage Promo');
  }
};

export default () => {
  window.addEventListener('load', promoImpression);
}
