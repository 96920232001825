import expandableMenu from './expandable-menu/expandable-menu';
import setLinkTarget from './behavior/set-link-target';
import responsiveEmbeds from './responsive/responsive-embeds';
import menuRollup from './menu/menu-rollup';
import userTimings from '../user-timings';
import prebid from './analytics/prebid';
import setHeaderWaypoint from './set-header-waypoint/set-header-waypoint';
import initMembershipsFeatures from './memberships/init-memberships-features';
import emailOnlyForms from './form-submit/email-only-forms';
import newsletterSplash from './form-submit/newsletter-splash';
import partnerOptin from './form-submit/partner-optin';
import recentQuestions from './recent-questions/recent-questions';
import toggleComments from './comments/toggle-comments';
import recordSelectorUsage from './analytics/record-selector-usage';
import homePromo from './home/promo';
import emblaCarousel from "./carousel/embla-carousel";
import accountPanel from "./account-panel/account-panel";

expandableMenu();
setLinkTarget();
toggleComments();
responsiveEmbeds();
menuRollup();
userTimings();

emailOnlyForms();
initMembershipsFeatures();
newsletterSplash();
partnerOptin();
recentQuestions();
recordSelectorUsage();
homePromo();
emblaCarousel();
accountPanel();

document.addEventListener('DOMContentLoaded', () => {
  prebid();
  setHeaderWaypoint();
});
