// Podcast Iframes Handlers
import $ from "jquery";

function responsivizeYoutubeIframes($container) {
  var $youtubeIframe = $container.find("iframe[src*='www.youtube.com']");

  $youtubeIframe.parent().addClass('iframe__wrap');
  $youtubeIframe.addClass('iframe__responsive');
}

function responsivizeLibsynIframes($container) {
  var $libsynIframe = $container.find("iframe[src*='html5-player.libsyn.com']"),
    $iTunesLink =   $container.find("a[href*='itunes.apple.com']"),
    $iHeartLink =   $container.find("a[href*='www.iheart.com']");

  $libsynIframe.unwrap().unwrap().unwrap().unwrap();
  $iTunesLink.unwrap().addClass('podcast-link');
  $iHeartLink.unwrap().addClass('podcast-link');
  $libsynIframe.addClass('iframe__responsive').wrap("<div class='iframe__wrap iframe__wrap--libsyn'></div>");

}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const $articleBody = $('.article__body');

    // Kick off Iframe Responsivizing
    responsivizeYoutubeIframes($articleBody);
    responsivizeLibsynIframes($articleBody);
  });
}
